import { isRecord } from "@kablamo/kerosene";
import type {
  AppBFMCsListResponse,
  AppFile,
  AppInputsListResponse,
  AppOutputsListResponse,
  AppProjectsListResponse,
  AppProjectUsersListResponse,
  AppRunResponse,
  AppTreatmentAreaResponse,
  AppUsersListResponse,
  AppWeatherDataResponse,
  AppWeatherPackageResponse,
  AppWeatherZonesListResponse,
} from "../.rest-hooks/types";
import type { JsonApi } from "../utils/deserializeJsonApi/deserializeJsonApi";

/**
 * Used in place of a real `File` for inputs
 */
export interface FakeFile extends Pick<File, "name"> {
  hint: AppFile["hint"];
}

export type BFMC = JsonApi.Deserialize<AppBFMCsListResponse>["data"][number];

export type Input = JsonApi.Deserialize<AppInputsListResponse>["data"][number];

export type Output =
  JsonApi.Deserialize<AppOutputsListResponse>["data"][number];

export type Project =
  JsonApi.Deserialize<AppProjectsListResponse>["data"][number];

export type ProjectUser =
  JsonApi.Deserialize<AppProjectUsersListResponse>["data"][number];

export type Run = JsonApi.Deserialize<AppRunResponse>["data"];

export type User = JsonApi.Deserialize<AppUsersListResponse>["data"][number];

export type WeatherRegion =
  JsonApi.Deserialize<AppWeatherZonesListResponse>["data"][number];

export type ProjectType = Project["type"];

export type OutputStatusPending = Extract<Output, { status: "pending" }>;

export type OutputStatusReady = Extract<Output, { status: "ready" }>;

export type OutputStatusFailed = Extract<Output, { status: "failed" }>;

export type WeatherData = JsonApi.Deserialize<AppWeatherDataResponse>["data"];

export type WeatherPackage =
  JsonApi.Deserialize<AppWeatherPackageResponse>["data"];

export type TreatmentArea =
  JsonApi.Deserialize<AppTreatmentAreaResponse>["data"];

/**
 * Project categories. Note that these enum values are displayed in UI as-is.
 */
export enum ProjectCategory {
  BFRMP = "BFRMP",
  LOCAL = "Local",
  STATEWIDE = "Statewide",
}

export const PROJECT_CATEGORY_TYPE_MAP = {
  [ProjectCategory.BFRMP]: ["bfrmp"],
  [ProjectCategory.LOCAL]: [
    "individual-treatment-comparison",
    "individual-treatment-comparison-brigade",
    "modelled-impact-time",
  ],
  [ProjectCategory.STATEWIDE]: [
    "statewide-maxfuels",
    "statewide-snapshot",
    "annual-works-plan-forecast",
    "annual-works-plan-evaluation",
  ],
} as const satisfies Record<ProjectCategory, ReadonlyArray<ProjectType>>;

export const getProjectTypesForCategory = <
  TProjectCategory extends ProjectCategory,
>(
  projectCategory: TProjectCategory,
): (typeof PROJECT_CATEGORY_TYPE_MAP)[TProjectCategory] => {
  return PROJECT_CATEGORY_TYPE_MAP[projectCategory];
};

export type BfrmpProject = Extract<Project, { type: "bfrmp" }>;

export type StatewideProject = Extract<
  Project,
  {
    type: (typeof PROJECT_CATEGORY_TYPE_MAP)[ProjectCategory.STATEWIDE][number];
  }
>;

export const toFakeFile = (appFile: AppFile): FakeFile => ({
  name: appFile.filename,
  hint: appFile.hint,
});

export const isRealOrFakeFile = (obj: unknown): obj is File | FakeFile => {
  if (obj instanceof File) return true;

  if (!isRecord(obj)) return false;
  if (!("name" in obj) || typeof obj.name !== "string") return false;

  if (
    "hint" in obj &&
    typeof obj.hint !== "string" &&
    typeof obj.hint !== "undefined"
  ) {
    return false;
  }

  return true;
};
